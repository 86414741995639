<template>
  <!--<div class="flex flex-col flex-shrink items-center gap-4 h-full pt-28 pb-65px bg-primary">-->
  <div class="flex flex-col flex-shrink items-center gap-4">
    <div class="flex w-full items-center mt-10">
      <div class="flex flex-row flex-wrap gap-4 w-full justify-center countdown"
           :class="`${card.expired ? 'bg-red-600 text-white' : 'bg-secondary text-white'}`">
        <!-- YEARS -->
        <div v-if="countdown.years > 0" class="flex flex-col justify-center items-center">
          <div class="text" :class="{'text-[#4D4C4C]': !card.expired}">Jahr(e)</div>
          <div class="number">{{ countdown.years }}</div>
        </div>

        <!-- MONTHS -->
        <div class="flex flex-col justify-center items-center">
          <div class="text" :class="{'text-[#4D4C4C]': !card.expired}">Monat(e)</div>
          <div class="number">{{ countdown.months }}</div>
        </div>

        <!-- DAYS -->
        <div class="flex flex-col justify-center items-center">
          <div class="text" :class="{'text-[#4D4C4C]': !card.expired}">Tag(e)</div>
          <div class="number">{{ countdown.days }}</div>
        </div>

        <!-- HOURS -->
        <div class="flex flex-col justify-center items-center">
          <div class="text" :class="{'text-[#4D4C4C]': !card.expired}">Stunde(n)</div>
          <div class="number">{{ countdown.hours }}</div>
        </div>

        <!-- MINUTES -->
        <div class="flex flex-col justify-center items-center">
          <div class="text" :class="{'text-[#4D4C4C]': !card.expired}">Minute(n)</div>
          <div class="number">{{ countdown.minutes }}</div>
        </div>

        <!-- SECONDS -->
        <div class="flex flex-col justify-center items-center">
          <div class="text" :class="{'text-[#4D4C4C]': !card.expired}">Sekunden</div>
          <div class="number">{{ countdown.seconds }}</div>
        </div>
      </div>
    </div>

    <div class="flex flex-col flex-shrink min-h-[490px] h-auto max-w-[320px] gap-2 w-full bg-white rounded-md mb-65px">
      <!-- CARD CONTENT -->
      <div v-if="content === 'card'">
        <div class="flex flex-col gap-2 h-[250px] items-center mx-4 mt-4">
          <div class="text-secondary text-3xl font-bold font-heading text-headline uppercase">Ihre Gästekarte</div>
          <div class="flex flex-grow items-center justify-center">
            <div class="flex items-center justify-center h-[155px] w-[155px]">
              <img v-if="!card.expired" class="h-[155px] w-[155px]" :src="`data:image/png;base64, ${card.base64QrCode}`" alt="digi card"/>
              <!-- EXPIRED TEXT-->
              <div v-else class="flex flex-col gap-4 items-center">
                <div class="text-red-600 font-bold uppercase text-2xl">
                  <i class="fa-solid fa-2xl fa-xmark"></i>
                </div>
                <div class="text-red-600 font-bold uppercase text-2xl">Abgelaufen</div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-center items-center">
          <div class="left-circle"></div>
          <div class="border-b-4 border-dashed flex-grow">
          </div>
          <div class="right-circle"></div>
        </div>

        <div class="px-4 pb-4 flex flex-col gap-4">
          <div class="flex flex-col font-heading text-[20px]">
            <div class="flex flex-row items-center">
              <div class="text-secondary">Bergstraße-Odenwald-Card</div>
              <i @click="content = 'info'"
                 class="fa-solid fa-circle-info text-secondary cursor-pointer pl-1"></i>
            </div>
            <div>{{ card.name }} {{ card.lastname }}</div>
            <div>Gültig bis: {{ toDate(card.departure) }}</div>
          </div>

          <div class="flex flex-col text-[14px] font-light">
            <div>Bei Kontrolle bitte Lichtbildausweis vorzeigen.</div>
            <div>VRN: Gültig im gesamten Tarifgebiet.</div>
            <div>RMV: Gültig in den Tarifgebieten 3900 – 4200.</div>
          </div>
        </div>
      </div>
      <!-- INFO CONTENT -->
      <div v-else>
        <div class="flex flex-row">
          <div class="left-circle flex-grow mt-[266px]"></div>
          <div class="flex flex-col flex-grow flex-wrap items-center text-center gap-4 m-4">
            <div class="text-secondary text-text font-bold text-[20px]">
              Die Bergstraße-Odenwald-Card ist nicht übertragbar. Sie gilt nur in Verbindung mit einem amtlichen
              Lichtbildausweis und für die Dauer des registrierten Aufenthaltes bzw. des aufgeführten Zeitraums. Es
              gelten
              die
              Datenschutzhinweise und Nutzungsbedingungen.
            </div>

            <div>
              <i class="fa-solid fa-circle-xmark text-secondary cursor-pointer" @click="content = 'card'"></i>
            </div>
          </div>
          <div class="right-circle flex-grow mt-[266px]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "BocCardComponent",
  props: ['card'],
  data() {
    return {
      content: "card",
      countdown: {
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }
  },
  created() {
    let duration = moment.duration((this.card.departure * 1000) - Date.now());

    if (duration < 0) {
      return;
    }

    this.setCountdown(duration);
    const interval = 1000;

    setInterval(() => {
      duration = moment.duration(duration - interval, 'milliseconds');

      if (duration > 1)
        this.setCountdown(duration);
    }, interval);
  },
  methods: {
    toDate(date) {
      return moment(date * 1000).format('DD.MM.YYYY')
    },
    setCountdown(duration) {
      this.countdown = {
        years: duration.years(),
        months: duration.months(),
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds()
      };
    }
  }
}
</script>

<style lang="scss">
.left-circle {
  min-width: 15px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 0 25px 25px 0;

  @apply bg-primary
}

.right-circle {
  min-width: 15px;
  min-height: 25px;
  max-height: 25px;
  border-radius: 25px 0 0 25px;

  @apply bg-primary
}

.countdown {
  .text {
    font-size: 12px;
  }

  .number {
    font-size: 22px;
  }
}
</style>