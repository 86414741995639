<template>
  <div class="grid grid-cols-1">
    <div class="text-secondary uppercase text-3xl font-bold font-heading text-headline">{{ title }}</div>
    <!-- Search -->
    <SearchComponent :modelValue="modelValue"
                     @input="$emit('update:modelValue', $event.target.value)"
                     :placeholder="placeholder"
    ></SearchComponent>
  </div>
</template>

<script>
import SearchComponent from "@/components/misc/SearchComponent";

export default {
  name: "HeaderSearchComponent",
  components: {SearchComponent},
  props: {
    modelValue: {
      required: true
    },
    title: {
      required: true,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>