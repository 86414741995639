import {defineStore} from 'pinia';
import Axios from "@/utility/axios";
import {newEntity} from "@/utility/entity";

export const useEntitiesStore = defineStore({
    id: 'entities',
    state: () => ({
        entities: [],
        responses: [],
        liked: JSON.parse(localStorage.getItem('liked') ?? '[]')
    }),
    getters: {
        getEntities: (state) => {
            return state.entities;
        },
        getLiked: (state) => {
            return state.liked;
        },
        isLiked: (state) => {
            return (id) => state.liked.includes(id)
        }
    },
    actions: {
        switchLike(id) {
            const index = this.liked.findIndex((like) => like === id);

            if (index === -1) {
                this.liked.push(id);
            } else {
                this.liked.splice(index, 1);
            }

            localStorage.setItem('liked', JSON.stringify(this.liked));
        },
        searchByFilter(filter, hitCache = true) {
            const index = this.responses.findIndex((response) => response.filter === filter);

            if (index !== -1 && hitCache) {
                return this.responses[index].data;
            } else {
                return Axios.get("filter", `/api/boc/cockpit/filter?${filter}`).then((res) => {
                    this.responses.push({
                        filter,
                        data: {
                            hits: res.data.data.hits.map((entity) => newEntity(entity)),
                            total: res.data.data.total
                        }
                    });

                    return {
                        hits: res.data.data.hits.map((entity) => newEntity(entity)),
                        total: res.data.data.total
                    };
                });
            }
        }
    }
});
