import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MapView from "@/views/MapView";
import BookmarksView from "@/views/BookmarksView";
import OffersView from "@/views/OffersView";
import BocCardView from "@/views/boc/BocCardView";
import DetailView from "@/views/DetailView";
import ErrorView from "@/views/ErrorView";
import ListingView from "@/views/ListingView";
import ActivationView from "@/views/ActivationView";
import MapPopupComponent from "@/components/misc/MapPopupComponent";
import BocView from "@/views/boc/BocView";
import BocCardInformationComponent from "@/components/boc/BocCardInformationComponent";
import BocCardComponent from "@/components/boc/BocCardComponent";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/card',
        name: 'card',
        children: [
            {
                path: '',
                name: 'boc-view',
                component: BocView,
            },
            {
                path: 'boc',
                name: 'boc',
                component: BocCardView,
                children: [
                    {
                        path: '',
                        name: 'boc-card',
                        component: BocCardComponent
                    },
                    {
                        path: 'info',
                        name: 'info',
                        component: BocCardInformationComponent
                    }
                ]
            }
        ]
    },
    {
        path: '/offers',
        name: 'offers',
        component: OffersView
    },
    {
        path: '/bookmarks',
        name: 'bookmarks',
        component: BookmarksView
    },
    {
        path: '/map',
        name: 'map',
        component: MapView
    },
    {
        path: '/listing:pathMatch(.*)*',
        name: 'listing',
        component: ListingView
    },
    {
        path: '/detail/:id',
        name: 'detail',
        props: true,
        component: DetailView
    },
    {
        path: '/activation',
        name: 'activation',
        component: ActivationView
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: {name: 'error'}
    },
    {
        path: '/',
        name: 'error',
        component: ErrorView
    },
    {
        path: '/popup',
        name: 'popup',
        component: MapPopupComponent
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    }
})

export default router
