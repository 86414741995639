export function process(entity) {
    const contacts = {
        primary: [],
        secondary: [],
        count: 0
    }

    if (entity.contactPoint_content) {
        const contactPoints = JSON.parse(entity.contactPoint_content);
        const filter = ['contact_person', 'primary_contact', 'organizer', 'operator'];
        contacts.count = contactPoints.length;

        for (const contactPoint of contactPoints) {
            if (contactPoint.contactType && contactPoint.contactType[0]) {
                contacts[filter.includes(contactPoint.contactType[0]) ? 'primary' : 'secondary'].push(contactPoint);
            } else {
                contacts.primary.push(contactPoint);
            }
        }
    }

    return contacts;
}