<template>
  <div class="flex flex-row">
    <div class="left-circle flex-grow mt-[266px]"></div>
    <div class="flex flex-col flex-grow flex-wrap items-center text-center gap-4 m-4">
      <div class="text-secondary text-text font-bold text-[20px]">
        Die Bergstraße-Odenwald-Card ist nicht übertragbar. Sie gilt nur in Verbindung mit einem amtlichen
        Lichtbildausweis und für die Dauer des registrierten Aufenthaltes bzw. des aufgeführten Zeitraums. Es gelten die
        Datenschutzhinweise und Nutzungsbedingungen.
      </div>

      <div>
        <i class="fa-solid fa-circle-xmark text-secondary cursor-pointer" @click="$router.push({path: '/card/boc'})"></i>
      </div>
    </div>
    <div class="right-circle flex-grow mt-[266px]"></div>
  </div>
</template>

<script>
export default {
  name: "BocCardInformationComponent"
}
</script>

<style scoped>

</style>