<template>
  <div class="flex flex-col gap-2 boc-container pt-28 pb-65px">
    <div class="grid grid-cols-1">
      <header-component title="Meine Merkliste" placeholder="Merkliste durchsuchen..."/>
    </div>

    <!-- Listing -->
    <div v-if="filterLink">
      <ListingComponent :filter-link="filterLink" direction="horizontal" :cache="false" :use-search="true"></ListingComponent>
    </div>
    <div v-else>
      <div
          class="flex justify-center text-secondary content-center justify-self-center justify-center items-center font-bold text-4xl">
        Keine Einträge vorhanden!
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useEntitiesStore} from "@/stores/entities";
import ListingComponent from "@/components/module/Listing";
import HeaderComponent from "@/components/misc/HeaderComponent";

export default {
  name: "BookmarksView",
  components: {HeaderComponent, ListingComponent},
  data() {
    return {
      filterLink: undefined
    }
  },
  computed: {
    ...mapStores(useEntitiesStore)
  },
  created() {
    if (this.entitiesStore.getLiked.length > 0)
      this.filterLink = `id=${this.entitiesStore.getLiked.toString().replaceAll(',', ':')}`;
  }
}
</script>

<style scoped>

</style>