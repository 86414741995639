<template>
  <div class="flex flex-col gap-4 pt-28 pb-65px">
    <div class="grid grid-cols-1 px-6">
      <header-search-component v-model="search" title="Angebote in der nähe" placeholder="Karte durchsuchen..."/>
    </div>

    <div id="venus-boc__map-view" class="w-full">
      <LibreMap></LibreMap>
      <!--
      <iframe id="venus-module__boc_maps"
              class="h-full w-full"
              allow="midi 'src'; geolocation 'src'; encrypted-media 'src';"
              :src="`https://pwa.module.bergstrasse-odenwald.de/maps`"
      ></iframe>
      -->
    </div>
  </div>
</template>

<script>
//import LibreMap from "@/components/LibreMap";
import HeaderSearchComponent from "@/components/misc/HeaderSearchComponent";
import LibreMap from "@/components/LibreMap";
import _ from "lodash";

export default {
  name: "MapView",
  components: {LibreMap, HeaderSearchComponent},
  data() {
    return {
      search: undefined,
    }
  },
  watch: {
    'search'() {
      if (this.searching)
        return;

      const debounce = _.debounce(() => {
        this.$router.push({path: `/listing`, query: {query: this.search}});
      }, 1000);

      debounce();
    }
  }
}
</script>

<style scoped>
#venus-boc__map-view {
  height: calc(100vh - 260px);
}
</style>