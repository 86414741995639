<template>
  <div class="grid grid-cols-1 gap-2 boc-container pt-28">
    <div class="grid grid-cols-1 gap-2">
      <div v-if="!filterLink" class="flex flex-col gap-4">
        <HeaderComponent v-model="search" title="Unsere Angebote" placeholder="Alle Angebote durchsuchen..."/>

        <div class="flex flex-row flex-wrap gap-2">
          <a v-for="(entity, index) of entities" :key="index" class="flex flex-row cursor-pointer tile"
             :href="toUrl(entity.data('url', true))"
          >
            <!-- @click="$router.push(`/listing?${filter.url}`)" -->
            <div class="relative tile hover:shadow-2xl">
              <img class="tile"
                   :src="entity.getImageUrl()"
                   :alt="`Kategorie ${entity.getName()}`"
              />

              <div
                  class="absolute bottom-0 left-0 right-0 bottom-0 px-2 text-white text-headline font-bold bg-secondary bg-opacity-80">
                {{ entity.getName() }}
              </div>
            </div>
          </a>
        </div>
      </div>
      <!--
      <div v-else class="flex flex-col gap-4 pb-24">
        <div class="text-secondary cursor-pointer" @click="filterLink = undefined">
          <i class="fas fa-angle-left fa-2xl"></i>
          <span class="pl-1">Zurück</span>
        </div>
        <ListingComponent :filter-link="filterLink" direction="horizontal"></ListingComponent>
      </div>
      -->
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useEntitiesStore} from "@/stores/entities";
import HeaderComponent from "@/components/misc/HeaderComponent";

export default {
  name: "OffersView",
  components: {HeaderComponent},
  data() {
    return {
      search: undefined,
      entities: [],
      filterLink: undefined
    }
  },
  computed: {
    ...mapStores(useEntitiesStore)
  },
  async created() {
    this.entities = (await this.entitiesStore.searchByFilter('type=FilteredPage')).hits;
  },
  methods: {
    toUrl(url) {
      if (!url)
        return '/listing';

      return url.includes('listing') ? url : `/listing?${url}`;
    }
  }
}
</script>

<style scoped>
.tile {
  width: 154px;
  height: 154px;
}
</style>