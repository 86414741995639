<template>
  <div class="grid grid-cols-1 gap-2 boc-container pt-28">
    <div class="text-secondary cursor-pointer" @click="$router.back()">
      <i class="fas fa-angle-left"></i>
      <span class="pl-1">Zurück</span>
    </div>

    <div class="grid grid-cols-1 gap-4">
      <ListingComponent :filter-link="filterLink" direction="horizontal" :cache="true"
                        :use-search="true"></ListingComponent>
    </div>
  </div>
</template>

<script>
import ListingComponent from "@/components/module/Listing";

export default {
  name: "ListingView",
  components: {ListingComponent},
  data() {
    return {
      filterLink: undefined
    }
  },
  created() {
    const query = new URLSearchParams(window.location.search).toString();

    if (query)
      this.filterLink = query;
  }
}
</script>

<style scoped>

</style>