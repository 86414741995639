import {createApp} from 'vue'
import {createPinia} from 'pinia';
import App from './App.vue'
import './assets/css/tailwind.css'
import './assets/css/main.css'
import './registerServiceWorker'
import router from './router'

/*
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faHome,
    faIdCard,
    faHeart,
    faMap,
    faSearch,
} from '@fortawesome/free-solid-svg-icons'

import {
    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube
} from '@fortawesome/free-brands-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
    faHome,
    faIdCard,
    faHeart,
    faMap,
    faSearch,
    faTwitter,
    faFacebook,
    faInstagram,
    faYoutube
);
 */

/*
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    fas
} from '@fortawesome/free-solid-svg-icons'

import {
    fab
} from '@fortawesome/free-brands-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(
    fas,
    fab
);
 */
const pinia = createPinia();
createApp(App)
    .use(pinia)
    .use(router)
    //.component('font-awesome-icon', FontAwesomeIcon)
    .mount('#venus-boc-pwa');