import {defineStore} from 'pinia';
import Axios from "@/utility/axios";

export const useModuleStore = defineStore({
    id: 'module',
    state: () => ({
        languages: []
    }),
    getters: {
        getLanguage(state) {
            return (type) => state.languages.find((language) => language.type === type);
        },
        getValue() {
            return (key) => {
                const keySegments = key.split('.');
                const language = this.getLanguage(keySegments[0]);

                if (!language)
                    return undefined;

                let languageObject = undefined;

                for (let i = 1; i < keySegments.length; i++) {
                    if (languageObject) {
                        languageObject = languageObject[keySegments[i]];

                        if (!languageObject)
                            return undefined;
                    } else {
                        languageObject = language.languageSet[keySegments[i]];
                    }
                }

                return languageObject;
            }
        }
    },
    actions: {
        async fetchLanguages() {
            for (const type of ['datamodel', 'features', 'messages']) {
                const language = this.getLanguage(type);

                if (language) {
                    // cache is still valid
                    if (language.cached > Date.now())
                        continue;
                }

                await Axios.get(`language/${type}`, `https://generator.we2p.de/api/language/default/${type}/DE`).then((res) => {
                    this.languages.push({
                        cached: Date.now() + 36000,
                        ...res.data.result
                    });
                });
            }
        }
    }
});
