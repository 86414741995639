<template>
  <div class="relative text-boc-gray focus-within:text-gray-400 border border-gray-500 rounded-md md:w-1/2">
    <span class="absolute inset-y-0 left-0 flex items-center pl-2">
      <button type="submit" class="p-1 focus:outline-none focus:shadow-outline">
        <i class="fas fa-search text-boc-light-gray"></i>
      </button>
    </span>

    <input type="search"
           name="q"
           class="py-2 text-sm text-secondary rounded-md pl-10 border-none focus:ring-0 focus:ring-secondary w-full dark:text-black search_input"
           :placeholder="placeholder"
           autocomplete="off"
           :value="modelValue"
           @input="$emit('update:modelValue', $event.target.value)"
    >
  </div>
</template>

<script>
export default {
  name: "SearchComponent",
  props: {
    modelValue: {
      required: true
    },
    placeholder: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>