<template>
  <div>
    <div v-if="!loading" class="pb-65px pt-28">

      <!-- SHOW CARD -->
      <div>
        <!-- IMAGE with text
        <img class="left-0 top-0 block object-cover w-full" src="@/assets/landing-page_banner.jpg"
             alt="boc_banner" style="height: 500px"/>
             -->

        <!-- CONTENT CONTAINER -->
        <div class="gap-2 flex flex-col items-center boc-container">
          <div class="grid grid-cols-1 gap-2 pt-6">
            <div class="grid grid-cols-1 gap-4">
              <div class="text-secondary text-headline uppercase font-bold font-heading">
                WILLKOMMEN IN DER REGION BERGSTRASSE-ODENWALD
              </div>
              <div class="font-text font-light text-boc-gray">
                Du hast dich für einen Urlaub in unserer Region entschieden und für die Bergstraße-Odenwald-Card. Nutze
                hier die Vorteile:
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center text-white bg-secondary gap-4 p-4 mt-12">
          <img class="w-[318px] rounded-md" src="@/assets/boc_logo_weiss.jpg" alt="BOC Logo"/>
          <div class="text-text text-2xl font-bold cursor-pointer" @click="$router.push({path: '/card/boc'})">Karte
            aufrufen
          </div>
        </div>
      </div>

      <!-- SCANNER -->
      <div v-if="false" class="gap-2 pb-65px flex flex-col items-center boc-container">
        <div class="h-[318px] h-fit border-2 border-solid border-[#707070]">
          <qr-stream @decode="onDecode" @init="onInit"/>
        </div>

        <div class="text-[20px] font-heading text-secondary text-center">
          QR-Code auf Ihrer Buchung einmal scannen und BOC immer am Smartphone dabei haben!
        </div>

        <div class="container">
          <img src="@/assets/gaestecard_scannen_grafik.svg" alt="scannen" class="w-full"/>
          <div class="pulse">
          </div>
          <div class="qr">
            <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" height="51" width="51"
                 viewBox="0 0 40 41">
              <g id="Rechteck_31" data-name="Rechteck 31">
                <rect width="40" height="41" style="fill: #fff"/>
                <rect x="1.5" y="1.5" width="37" height="38" style="fill: none;stroke: #a3bf3c;stroke-width: 3px"/>
              </g>
              <g id="Rechteck_32" data-name="Rechteck 32">
                <rect width="11" height="11" style="fill: none"/>
                <rect x="1.5" y="1.5" width="8" height="8" style="fill: none;stroke: #a3bf3c;stroke-width: 3px"/>
              </g>
              <g id="Rechteck_33" data-name="Rechteck 33">
                <rect x="29" width="11" height="11" style="fill: none"/>
                <rect x="30.5" y="1.5" width="8" height="8" style="fill: none;stroke: #a3bf3c;stroke-width: 3px"/>
              </g>
              <g id="Rechteck_35" data-name="Rechteck 35">
                <rect y="30" width="11" height="11" style="fill: none"/>
                <rect x="1.5" y="31.5" width="8" height="8" style="fill: none;stroke: #a3bf3c;stroke-width: 3px"/>
              </g>
              <rect id="Rechteck_37" data-name="Rechteck 37" x="26" y="8" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_44" data-name="Rechteck 44" x="22" y="11" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_45" data-name="Rechteck 45" x="14" y="11" width="5" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_60" data-name="Rechteck 60" x="14" y="30" width="5" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_72" data-name="Rechteck 72" x="11" y="33" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_71" data-name="Rechteck 71" x="19" y="33" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_38" data-name="Rechteck 38" x="22" y="4" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_56" data-name="Rechteck 56" x="14" y="22" width="5" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_53" data-name="Rechteck 53" x="19" y="19" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_47" data-name="Rechteck 47" x="22" y="15" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_49" data-name="Rechteck 49" x="33" y="15" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_42" data-name="Rechteck 42" x="11" y="11" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_39" data-name="Rechteck 39" x="19" y="4" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_57" data-name="Rechteck 57" x="11" y="22" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_52" data-name="Rechteck 52" x="14" y="19" width="5" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_46" data-name="Rechteck 46" x="19" y="15" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_48" data-name="Rechteck 48" x="29" y="15" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_62" data-name="Rechteck 62" x="29" y="11" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_43" data-name="Rechteck 43" x="8" y="11" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_40" data-name="Rechteck 40" x="11" y="4" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_58" data-name="Rechteck 58" x="3" y="22" width="5" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_61" data-name="Rechteck 61" x="8" y="25" width="3" height="5" style="fill: #a3bf3c"/>
              <rect id="Rechteck_51" data-name="Rechteck 51" x="8" y="19" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_54" data-name="Rechteck 54" x="26" y="19" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_66" data-name="Rechteck 66" x="22" y="25" width="4" height="5" style="fill: #a3bf3c"/>
              <rect id="Rechteck_69" data-name="Rechteck 69" x="33" y="30" width="4" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_55" data-name="Rechteck 55" x="33" y="19" width="4" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_63" data-name="Rechteck 63" x="29" y="22" width="4" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_67" data-name="Rechteck 67" x="26" y="30" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_64" data-name="Rechteck 64" x="22" y="22" width="4" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_65" data-name="Rechteck 65" x="19" y="30" width="3" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_70" data-name="Rechteck 70" x="22" y="33" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_68" data-name="Rechteck 68" x="29" y="33" width="4" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_50" data-name="Rechteck 50" x="11" y="15" width="3" height="4" style="fill: #a3bf3c"/>
              <rect id="Rechteck_41" data-name="Rechteck 41" x="14" y="8" width="5" height="3" style="fill: #a3bf3c"/>
              <rect id="Rechteck_59" data-name="Rechteck 59" x="14" y="25" width="5" height="5" style="fill: #a3bf3c"/>
            </svg>
          </div>
        </div>


        <!--
        <img
            src="@/assets/gaestecard_scannen_grafik.svg"
            alt="Gaestecard Grafik"
        />
        -->
      </div>
    </div>
    <div v-else class="flex flex-col min-h-[100vh] items-center justify-center">
      <i class="fa-solid fa-circle-notch fa-spin text-5xl text-secondary"></i>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useBocCardStore} from "@/stores/bocCard";
import {QrStream} from "vue3-qr-reader";

export default {
  name: "BocView",
  components: {
    QrStream
  },
  computed: {
    ...mapStores(useBocCardStore)
  },
  data() {
    return {
      loading: true
    }
  },
  async created() {
    this.loading = true;
    const hash = new URL(location.href).searchParams.get('hash');

    // TODO: add error handling
    if (hash && hash !== '') {
      await this.bocCardStore.getByHash(hash);
    }

    await this.bocCardStore.getCards().catch(() => {
    });

    if (this.bocCardStore.bocCards.length <= 0)
      this.$router.push('activation');

    this.loading = false;
  },
  methods: {
    onDecode(decodedString) {
      if (decodedString.includes('activation'))
        this.$router.push('/activation');
    },
    async onInit(promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }

        console.log(this.error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  display: flex;
  justify-content: right;
  align-items: end;

  .qr {
    width: 51px;
    height: 51px;
    position: absolute;
    display: flex;
    justify-content: right;
    align-items: baseline;
    margin: 15px;
  }

  .pulse {
    position: absolute;
    display: flex;
    justify-content: right;
    align-items: baseline;
    width: 51px;
    height: 51px;
    border-radius: 50%;
    margin: 15px;
    animation: pulse 2s infinite;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(232, 188, 48, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 25px rgba(232, 188, 48, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(232, 188, 48, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(232, 188, 48, 0.6);
    box-shadow: 0 0 0 0 rgba(232, 188, 48, 0.6);
  }
  70% {
    -moz-box-shadow: 0 0 0 50px rgba(232, 188, 48, 0);
    box-shadow: 0 0 0 50px rgba(232, 188, 48, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(232, 188, 48, 0);
    box-shadow: 0 0 0 0 rgba(232, 188, 48, 0);
  }
}
</style>