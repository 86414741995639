import moment from "moment";

export default class Entity {

    constructor(rawData) {
        this.rawData = rawData;
        this.processed = [];
    }

    data(property, raw) {
        if (property === '_id')
            return this.rawData._id;

        if (raw)
            return this.rawData._source[property];

        try {
            let processedValue = this.processed[property];
            if (processedValue)
                return processedValue;

            processedValue = require(`../processors/${property}.processor`).process(this.rawData._source);
            this.processed[property] = processedValue;
            return processedValue;
        } catch (e) {
            return this.rawData._source[property];
        }
    }

    getName() {
        return this.data('name').find((name) => name.lang === 'de-DE')?.value
    }

    getImageUrl(usage = undefined) {
        if (this.data('MD_public')) {
            for (const image of this.data('MD_public')) {
                if (usage) {
                    if (image.fileUsage && image.fileUsage.includes(usage)) {
                        return image.thumbUrl ? `https://webimages.we2p.de/2/${image.thumbUrl}` : `https://media.we2p.de/cockpit/preview/${this.data('_id')}/${image.info.fileName}`;
                    }
                } else {
                    return image.thumbUrl ? `https://webimages.we2p.de/2/${image.thumbUrl}` : `https://media.we2p.de/cockpit/preview/${this.data('_id')}/${image.info.fileName}`;
                }
            }
        }

        return 'https://webimages.we2p.de/2/undefined';
    }

    getTranslation(key, from = undefined, raw = false) {
        let object = undefined;

        if (from)
            object = from;

        const keySegments = key.split('.');

        for (const segment of keySegments) {
            if (object) {
                object = object[segment];
            } else {
                object = this.data(segment, raw);
            }

            if (!object)
                return null;
        }

        return Array.isArray(object) ? object.find((translation) => translation.lang === 'de-DE')?.value : null;
    }

    formatDate(date, format) {
        return moment(date).format(format);
    }

    formatAddress(address) {
        const firstSegment = `${address.zip ? address.zip : ''} ${address.city ? address.city : ''}`;
        const addressString = `${firstSegment ? firstSegment + (address.street ? ',' : '') : ''} ${address.street ? address.street : ''}`;
        return addressString.length === 1 ? undefined : addressString;
    }

    getContact(type) {
        return this.data('contactPoint')[type];
    }

    truncate(text, stop, clamp) {
        if (!text)
            return "";

        return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
    }

    getShortDescription() {
        return this.getTranslation('shortDescription');
    }

    getDescription() {
        return this.getTranslation('description');
    }
}

export function newEntity(entity) {
    const entityObj = new Entity(entity);
    return new Proxy(entityObj, {
        get(target, prop, receiver) {
            const value = target[prop];
            if (value instanceof Function) {
                return function (...args) {
                    return value.apply(this === receiver ? target : this, args);
                };
            }

            return entityObj.data(prop);
        }
    });
}