<template>
  <div class="gap-2">
    <!-- IMAGE with text -->
    <img class="left-0 top-0 block object-cover w-full" src="@/assets/landing-page_banner.jpg"
         alt="boc_banner" style="height: 500px"/>

    <!-- CONTENT CONTAINER -->
    <div class="grid grid-cols-1 gap-2 pt-6 boc-container">
      <div class="grid grid-cols-1 gap-4">
        <div class="text-secondary text-headline uppercase font-bold font-heading">
          Alles in einer Karte, alles für dich!
        </div>
        <div class="font-text font-light text-boc-gray">
          Die BOC-Card ist Deine Bonuskarte für über 70 Freizeiteinrichtungen zwischen Rhein, Main und Neckar und Dein
          Ticket für die kostenlose Nutzung von Bussen und Bahnen der Destination Bergstraße-Odenwald!
          <br>
          <br>
          Ob <span class="underline text-secondary">Museum</span>, <span
            class="underline text-secondary">Therme</span>,
          <span class="underline text-secondary">Tierpark</span> oder <span class="underline text-secondary">Downhill im Bikepark</span>
          – zeig Deine BOC-Card einfach an der Kasse. Freier Eintritt, 50 %

          Ermäßigung, attraktive Rabatte und Gutscheine:
          <span class="font-bold">
            So sparst Du jeden Urlaubstag bares Geld beim erleben und
            genießen. Für deinen maximalen Urlaubsspaß!
          </span>
        </div>
      </div>

      <div class="grid grid-cols-1 gap-2 py-4">
        <div class="text-secondary uppercase font-bold font-heading text-headline">
          Highlights für dich!
        </div>

        <div>
          <!--<ListingComponent listing-style="slider" filter-link="type=Brewery:BookStore&sort=name,0"></ListingComponent>-->
          <ListingComponent direction="vertical" :cache="true" :use-search="false" filter-link=""></ListingComponent>
        </div>
      </div>
    </div>

    <!-- FOOTER -->
    <footer class="grid grid-cols-1 gap-2 text-white w-full bottom-0 pb-[4.3rem] text-[14px]">
      <div class="grid grid-cols-1 bg-secondary pt-8 pb-[15px] p-6">
        <div class="uppercase text-2xl font-bold font-heading text-headline pb-2">Du hast Fragen?</div>
        <div>Gern sind wir für Dich da.</div>
        <div>Von Montag bis Freitag, Ostern bis Allerheiligen:</div>
        <div>9:00 - 17:00 Uhr und im Winter: 10:00 - 15 Uhr</div>
        <br>
        <div>T +49 (0) 6061 - 965 970</div>
        <div>F +49 (0) 6061 - 965 972 0</div>
        <div>info@bergstrasse-odenwald.de</div>
        <!-- SPACER -->
        <div class="border border-0 border-b-4 border-white pt-2"></div>

        <div class="flex flex-row gap-[20px] justify-center pt-6">
          <a v-for="(social, index) of socials" :key="index" :href="social.href" target="_blank">
            <i :class="social.icon" class="fa-xs"></i>
          </a>
        </div>
      </div>
      <div class="grid grid-cols-1 bg-white text-light-gray text-center">
        <div>
          {{ `${new Date().getFullYear()} © Bergstrasse-Odenwald` }}
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import ListingComponent from "@/components/module/Listing";

export default {
  name: "HomeView",
  components: {ListingComponent},
  data() {
    return {
      socials: [
        {
          icon: 'fa-brands fa-twitter',
          href: 'https://twitter.com/odenwaldfan'
        }, {
          icon: 'fa-brands fa-facebook-f',
          href: 'https://www.facebook.com/odenwald.de/'
        }, {
          icon: 'fa-brands fa-instagram',
          href: 'https://www.instagram.com/tourismus_bergstrasse_odenwald/'
        }, {
          icon: 'fa-brands fa-youtube',
          href: 'https://www.youtube.com/channel/UCGMjx6x8hJeVyUmXHfbirmw?view_as=subscriber&pbjreload=101'
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>