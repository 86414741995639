<template>
  <div id="venus-boc__anchor_map" class="flex flex-col items-center h-full w-full">
    <div v-if="loading" class="text-text text-secondary">Lade Karte...</div>
    <div class="w-full h-full">
      <div id="venus-boc__map-container" style='width: 100%; height: 100%'></div>
    </div>
  </div>
</template>

<script>
import 'maplibre-gl/dist/maplibre-gl.css';

import {Map as LibreMap, NavigationControl, GeolocateControl, Marker, Popup, AttributionControl} from 'maplibre-gl';
import {mapStores} from "pinia";
import {useEntitiesStore} from "@/stores/entities";
import {createApp} from "vue";
import MapPopupComponent from "@/components/misc/MapPopupComponent";
import router from "@/router";

export default {
  name: "LibreMap",
  components: {},
  data() {
    return {
      loading: true,
      map: null,
      markers: [],
      icons: [
        {
          "type": "FoodEstablishment",
          "color": "orange",
          "icon": "fas fa-utensils",
          "image": undefined,
          "title": "Restaurants",
          //action: () => this.toggleByType("FoodEstablishment")
        },
        {
          "type": "LodgingBusiness",
          "color": "brown",
          "icon": "fas fa-bed",
          "image": undefined,
          "title": "Unterkünfte",
          //action: () => this.toggleByType("LodgingBusiness")
        },
        {
          "type": "TouristAttraction",
          "color": "dodgerblue",
          "icon": "fas fa-binoculars",
          "image": undefined,
          "title": "Touristenattraktionen",
          //action: () => this.toggleByType("TouristAttraction")
        },
        {
          "type": "Trail",
          "color": "green",
          "icon": "fas fa-route",
          "image": undefined,
          "title": "Wanderungen",
          //action: () => this.toggleByType("Trail")
        },
        {
          "type": "PublicTransportStop",
          "color": "#006600",
          "icon": "fas fa-bus",
          "image": "https://webimages.we2p.de/static/we2p_web_modules/static_images/vgn.svg",
          "title": "Nahverkehr",
          //action: () => this.toggleByType("PublicTransportStop")
        }
      ],
    }
  },
  mounted() {
    this.initMap();
  },
  computed: {
    ...mapStores(useEntitiesStore)
  },
  methods: {
    async initMap() {
      const canvas = document.getElementById('venus-boc__map-container')
      const userPosition = await this.getCurrentPosition(true);

      this.map = new LibreMap({
        container: canvas,
        style: 'https://tiles.venus.bayern/styles/venus-basic/style.json',
        attributionControl: false,
        /*
        style: {
          'version': 8,
          'sources': {
            'raster-tiles': {
              'type': 'raster',
              'tiles': [
                'https://map.tourinfra.com/tiles/gs_sommer_red/{z}/{x}/{y}.png'
              ],
              'tileSize': 256,
              'attribution':
                  '&copy; <a href="https://venus.bayern" title="VENUS GmbH®" target="_blank">VENUS GmbH</a> | ' +
                  '<a href="https://www.green-solutions.info/" title="www.green-solutions.info" target="_blank">green-solutions GmbH & Co. KG</a>, ' +
                  '<a href="https://www.openstreetmap.org/copyright" title="www.openstreetmap.org" target="_blank">OSM &amp; Contributors</a>'
            }
          },
          'layers': [
            {
              'id': 'simple-tiles',
              'type': 'raster',
              'source': 'raster-tiles',
              'minzoom': 0,
              'maxzoom': 22
            }
          ]
        },
         */
        zoom: 15,
        maxZoom: 18,
        locale: 'de',
      });

      this.map.addControl(new NavigationControl());

      this.map.addControl(new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true
        },
        trackUserLocation: true
      }));

      this.map.addControl(new AttributionControl({
        compact: false,
        customAttribution: [
          '&copy; <a href="https://venus.bayern" title="VENUS GmbH®" target="_blank">VENUS GmbH</a>',
          '<a href="https://openmaptiles.org/" rel="nofollow" target="_blank">© OpenMapTiles</a> <a href="https://www.openstreetmap.org/copyright" rel="nofollow" target="_blank">© OpenStreetMap contributors</a>'
        ]
      }), 'bottom-right');

      if (userPosition.lat !== 0 && userPosition.lat !== 0) {
        this.setCenter = true;
        this.map.setCenter(userPosition);
      }

      this.map.on('load', async () => {
        this.loading = false;
        const pois = await this.entitiesStore.searchByFilter({}, true);

        if (!pois)
          return;

        for (let poi of pois.hits) {
          poi.poi = true;
          this.createMarker(poi)?.addTo(this.map);
        }
      });
    },
    createMarker(entity, color = '#a1bf37') {
      if (!entity.coords || entity.coords.length === 0)
        return;

      let marker, types = entity.types_all ? entity.types_all : entity.types;

      const popupElement = document.createElement("div");
      popupElement.id = `popup${entity._id}`;

      const dialog = createApp({extends: MapPopupComponent}, {
        entity
      });
      dialog.use(router);
      dialog.mount(popupElement);

      const icon = this.getIconForTypes(types, color);

      if (icon) {
        let markerEl = document.createElement("div");

        markerEl.innerHTML = '<div class="we2p-map-maker" style="cursor:pointer">' +
            '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 28.35 33.71">' +
            '<g>' +
            '<path class="we2p-map-marker__path" style="fill: ' + icon.color + '" d="M28.35,13.88c0,9.49-14.17,19.83-14.17,19.83S0,23.37,0,13.88C0,6.21,6.35,0,14.17,0C22,0,28.35,6.21,28.35,13.88z"/>' +
            '</g>' +
            '</svg>' +
            '<div class="spinner"><i class="' + icon.icon + ' fa-1x we2p-map-marker__icon"></i></div>' +
            '</div>'

        const popup = new Popup({offset: [5, 0]}).setDOMContent(popupElement);
        marker = new Marker(markerEl, {
          color: icon.color,
          offset: [20, 0]
        }).setLngLat(this.toLatLng(entity.coords)).setPopup(popup);
      } else {
        const popup = new Popup().setDOMContent(popupElement);
        marker = new Marker({
          color: color,
        }).setLngLat(this.toLatLng(entity.coords)).setPopup(popup);
      }

      // add marker to markers list
      this.markers.push({
        marker: marker,
        hidden: false,
        types: types
      });

      return marker;
    },
    getIconForTypes(types) {
      for (let type of types) {
        const icon = this.icons.find(icon => icon.type === type);

        if (icon) {
          return icon;
        }
      }

      return null;
    },
    toLatLng(coords) {
      if (!coords) {
        return {lat: 0, lng: 0};
      }

      return {lat: coords[1] ? coords[1] : 0, lng: coords[0] ? coords[0] : 0};
    },
    async getCurrentPosition(asLatLng = false) {
      const format = {
        lat: 0,
        lng: 0
      }

      return new Promise((resolve) => {
        navigator.geolocation.getCurrentPosition(position => {
          if (!position) {
            resolve(asLatLng ? format : [0, 0]);
            return;
          }

          if (asLatLng) {
            format.lat = position.coords.latitude;
            format.lng = position.coords.longitude;
            resolve(format);
          } else {
            resolve([position.coords.latitude, position.coords.longitude]);
          }
        }, () => {
          resolve(asLatLng ? format : [0, 0]);
        });
      });
    },
  }
}
</script>

<style lang="scss">
.mapboxgl-popup-content, .maplibregl-popup-content {
  background: #fff;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 0;
  pointer-events: auto;
  position: relative;
}

.mapboxgl-popup-close-button {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  right: 5px;
  top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;

  @apply bg-primary;
  @apply rounded-full;
}

.we2p-map-maker {
  width: 30px;
  height: 40px;
  transform: translate(-50%, -50%);
  position: relative;
}

.we2p-map-marker__path {
  fill: var(--we2p-color-primary);
}

.we2p-map-marker__icon {
  position: absolute;
  left: 50%;
  top: 14px;
  color: #fff;
  transform: translate(-50%, -50%);
}


.map-overlay {
  border-radius: 10px 10px 10px 10px !important;
  //font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  position: absolute;
  //width: 200px;
  bottom: 0;
  left: 0;
  padding: 10px;
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 10px;
}

.map-overlay-inner fieldset {
  border: none;
  padding: 0;
  margin: 0 0 10px;
}

.map-overlay-inner fieldset:last-child {
  margin: 0;
}

.map-overlay-inner select {
  width: 100%;
}

.map-overlay-inner label {
  display: block;
  font-weight: bold;
  margin: 0 0 5px;
}

.map-overlay-inner button {
  display: inline-block;
  width: 36px;
  height: 20px;
  border: none;
  cursor: pointer;
}

.map-overlay-inner button:focus {
  outline: none;
}

.map-overlay-inner button:hover {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}
</style>
