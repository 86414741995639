<template>
  <div class="flex flex-col gap-2 boc-container pt-28 pb-65px">

    <div v-if="false" class="grid grid-cols-1">
      <header-component title="Entity"/>
    </div>

    <!-- Listing -->
    <div class="text-secondary cursor-pointer" @click="$router.back()">
      <i class="fas fa-angle-left"></i>
      <span class="pl-1">Zurück</span>
    </div>

    <div class="">
      <iframe id="venus-module__boc_detail" width="100%" height="600"
              :src="`https://pwa.module.bergstrasse-odenwald.de/detail/id=${id}`"></iframe>
    </div>

    <div v-if="false" class="grid grid-cols-1">
      <!-- TYPES -->
      <div class="flex flex-row flex-wrap text-boc-gray">
        <div v-for="(type, index) of entity.types_all" :key="index" class="text-sm">
          <span v-if="index !== 0" class="px-2">|</span>
          <i :class="moduleStore.getValue(`datamodel.datamodel.entitytype.${type}.icon`)"></i>
          <span class="pl-1">{{ moduleStore.getValue(`datamodel.datamodel.entitytype.${type}.title`) }}</span>
        </div>
      </div>

      <!-- NAME -->
      <div class="text-headline font-bold text-primary tracking-wide">
        {{ entity.getName() }}
      </div>

      <!-- ADDRESS -->
      <div v-if="entity.address && entity.formatAddress(entity.address)" class="text-sm">
        <i class="fas fa-map-marker-alt mr-1"></i>
        <span>{{ entity.formatAddress(entity.address) }}</span>
      </div>

      <!-- IMAGE -->
      <div class="max-h-[600px] p-6">
        <img :src="entity.getImageUrl()"
             :alt="entity.getName()"
             class="w-full object-contain max-h-[600px]"
        />
      </div>

      <div v-if="entity.getShortDescription()"
           class="venus-module__description text-text text-[1rem] font-bold text-secondary">
        <div v-html="entity.getShortDescription()">
        </div>
      </div>

      <div v-if="entity.getDescription()" class="venus-module__description text-text text-[1rem]">
        <div v-html="entity.getDescription()">
        </div>
      </div>

      <div class="pt-4 flex flex-col gap-6">
        <AccordionComponent icon="fa-solid fa-clock" title="Öffnungszeiten">
          <div v-for="(entry, index) of entity.openingHoursSpecification" :key="index">
            <div class="grid grid-cols-2">
              <div v-for="(day, index) of entry.dayOfWeek" :key="index">{{ day }}</div>
            </div>
          </div>
        </AccordionComponent>

        <AccordionComponent icon="fa-solid fa-coin" title="Preisinfo">
          <div>
            Hello
          </div>
        </AccordionComponent>

        <AccordionComponent icon="fa-solid fa-phone" title="Kontakt">
          <div>
            Hello
          </div>
        </AccordionComponent>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/misc/HeaderComponent";
import {mapStores} from "pinia";
import {useModuleStore} from "@/stores/module";
import {useEntitiesStore} from "@/stores/entities";
import AccordionComponent from "@/components/misc/AccordionComponent";

export default {
  name: "DetailView",
  components: {AccordionComponent, HeaderComponent},
  props: ['id'],
  data() {
    return {
      entity: undefined
    }
  },
  computed: {
    ...mapStores(useModuleStore, useEntitiesStore)
  },
  async created() {
    // TODO: fetch entity via id
    //await this.moduleStore.fetchLanguages();
    //this.entity = (await this.entitiesStore.searchByFilter(`?id=${this.id}`, false)).hits[0];
  }
}
</script>

<style lang="scss">
.venus-module__description {
  a {
    text-decoration: underline;
    @apply text-secondary
  }
}
</style>