import {defineStore} from 'pinia';
import axios from "@/utility/axios";

export const useBocCardStore = defineStore({
    id: 'bocCard',
    state: () => ({
        cards: [],
        aesPayload: localStorage.getItem('boc_card') ?? undefined
    }),
    getters: {
        bocCards(state) {
            return state.cards;
        },
        getAesPayload(state) {
            return state.aesPayload;
        }
    },
    actions: {
        async getByHash(hash) {
            return axios.get('avs/card', `/api/boc/avs/hash/${hash}`).then((response) => {
                const data = response.data.data;
                localStorage.setItem('boc_card', data.aesPayload);
                this.aesPayload = data.aesPayload;
                return data.cards;
            });
        },
        async getBySelfCheckIn(name, bookingCode) {
            return axios.post('avs/card', `/api/boc/avs/self-check-in`, {
                name,
                bookingCode
            }).then((response) => {
                const data = response.data.data;
                localStorage.setItem('boc_card', data.aesPayload);
                this.aesPayload = data.aesPayload;
                return data.cards;
            });
        },
        // get by cookie
        async getCards() {
            return new Promise((resolve, reject) => {
                if (!this.aesPayload) {
                    reject();
                    return;
                }

                if (!this.cards || this.cards.length === 0) {
                    axios.post('avs/card', `/api/boc/avs/cards`, {
                        aesPayload: this.aesPayload
                    }).then((response) => {
                        this.cards = response.data.data;
                        resolve();
                    }).catch((error) => {
                        localStorage.removeItem('boc_card');
                        reject(error);
                    });
                } else {
                    resolve();
                }
            });
        }
    }
});
