<template>
  <div class="flex flex-col gap-4 text-secondary items-center w-[200px]">
    <div class="rounded-t-md">
      <img class="object-cover rounded-t-md" style="width: 200px; height: auto" :src="entityValue.getImageUrl()"
           alt="Bild"/>
    </div>
    <div class="flex flex-col gap-4 pb-[1rem]">
      <div class="font-bold text-[18px] text-center cursor-pointer"
           @click="$router.push({path: '/detail/' + entity._id})">
        {{ entityValue.getName() }}
        <span class="cursor-pointer"><i
            class="fa-solid fa-arrow-up-right-from-square"></i></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapPopupComponent",
  props: [
    'entity'
  ],
  data() {
    return {
      entityValue: undefined
    }
  },
  created() {
    if (this.entity)
      this.entityValue = this.entity;

    /*
    if (!this.entityValue)
      this.entityValue = {
        name: 'Test'
      }
     */
  }
}
</script>

<style scoped>

</style>