<template>
  <div class="bg-primary h-full">
    <!--<router-view></router-view>-->
    <div v-for="(card, index) of bocCardStore.bocCards" :key="index" :class="{'pt-28': index === 0}">
      <BocCardComponent :card="card"></BocCardComponent>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useBocCardStore} from "@/stores/bocCard";
import BocCardComponent from "@/components/boc/BocCardComponent";

export default {
  name: "BocCardView",
  components: {BocCardComponent},
  data() {
    return {}
  },
  computed: {
    ...mapStores(useBocCardStore)
  },
  async created() {
    await this.bocCardStore.getCards();
  }
}
</script>