<template>
  <div class="venus__listing flex flex-col gap-4">

    <div>
      <SearchComponent v-if="useSearch" v-model="search" placeholder="Ergebnisse durchsuchen"></SearchComponent>
    </div>

    <!-- Listing -->
    <div v-if="!loading" class="flex gap-2 overflow-scroll snap-x"
         :class="direction === 'horizontal' ? 'flex-col' : 'flex-row'">
      <div v-for="(entity, index) of entities" :key="index" :id="entity._id" class="snap-start">
        <EntityComponent :entity="entity" :direction="direction"></EntityComponent>
        <div v-if="direction === 'horizontal' && index !== entities.length - 1"
             class="border border-b-[0.5px] text-boc-gray">
        </div>
      </div>

      <div v-if="entities.length === 0" class="flex justify-center text-secondary font-bold text-4xl">
        Es wurden keine Ergebnisse gefunden!
      </div>
    </div>

    <!-- Skeleton loader -->
    <div v-else class="flex gap-2 overflow-scroll snap-x" :class="direction === 'horizontal' ? 'flex-col' : 'flex-row'">
      <div v-for="(index) of [1,2,3,4,5]" :key="index" class="rounded-md snap-start">
        <div class="flex flex-grow gap-4 animate-pulse"
             :class="direction === 'horizontal' ? 'flex-col md:flex-row lg:flex-row flex-grow' : 'flex-col'">
          <div class="bg-gray-300 flex-grow rounded-md w-full" style="height: 180px; width: 270px;">
          </div>

          <div class="flex flex-col gap-2 flex-grow break-words pt-4 w-full">
            <div class="bg-gray-300 rounded-md" :style="`height: ${randomHeight(15, 25)}px`">
            </div>

            <div class="bg-gray-300 rounded-md" :style="`height: ${randomHeight(15, 30)}px`">
            </div>

            <div class="bg-gray-300 rounded-md" :style="`height: ${randomHeight(30, 100)}px`">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useEntitiesStore} from "@/stores/entities";
import {mapStores} from 'pinia';
import EntityComponent from "@/components/module/Entity";
import {useModuleStore} from "@/stores/module";
import SearchComponent from "@/components/misc/SearchComponent";
import _ from 'lodash';

export default {
  name: "ListingComponent",
  components: {SearchComponent, EntityComponent},
  props: {
    direction: {
      required: true,
      type: String
    },
    filterLink: {
      required: true,
      type: String
    },
    cache: {
      required: true,
      type: Boolean
    },
    useSearch: {
      required: true,
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      searching: false,
      entities: [],
      page: 1,
      pages: 0,
      size: 100,
      search: undefined
    }
  },
  async created() {
    this.loading = true;
    await this.moduleStore.fetchLanguages();
    await this.fetchEntities();
    this.loading = false;
  },
  computed: {
    ...mapStores(useEntitiesStore, useModuleStore)
  },
  watch: {
    'search'() {
      if (this.searching)
        return;

      const debounce = _.debounce(() => {
        this.searching = true;
        this.fetchEntities(true);
        this.searching = false;
      }, 500);

      debounce();
    }
  },
  methods: {
    async fetchEntities(cache) {
      const listingResponse = await this.entitiesStore.searchByFilter(this.buildFilter(), cache !== undefined ? cache : this.cache);
      this.pages = Math.round(listingResponse.total.value / this.size);
      this.entities = listingResponse.hits;
    },
    randomHeight(min, max) {
      return Math.floor(min + Math.random() * (max - min + 1))
    },
    buildFilter() {
      const url = new URL(window.location.href);
      url.searchParams.append('sheet', this.page);
      url.searchParams.append('size', this.size);

      if (url.searchParams.get('query'))
        this.search = url.searchParams.get('query');

      if (this.search)
        url.searchParams.append('query', this.search);

      return url.searchParams.toString();

      /*
      let url = new URL('https://venus.bayern');

      if (this.filterLink && this.filterLink.length > 0) {
        url = new URL(`https://venus.bayern?${this.filterLink}`);
      }

      url.searchParams.append('sheet', this.page);
      url.searchParams.append('size', this.size);

      if (url.searchParams.get('query'))
        this.search = url.searchParams.get('query');

      if (this.search)
        url.searchParams.append('query', this.search);

      return url.searchParams.toString();
       */
    }
  }
}
</script>

<style scoped>

</style>