<template>
  <div class="">
    <div class="flex flex-row bg-primary text-white p-4">
      <div class="flex-grow text-headline">
        <i :class="icon"></i>
        {{ title }}
      </div>
      <div class="text-headline">
        <i v-if="!open" class="fa-solid fa-plus cursor-pointer" @click="accordion"></i>
        <i v-else class="fa-solid fa-minus cursor-pointer" @click="accordion"></i>
      </div>
    </div>
    <div v-if="open" class="p-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccordionComponent",
  props: {
    icon: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      open: false
    }
  },
  methods: {
    accordion() {
      this.open = !this.open;
    }
  }
}
</script>

<style scoped>

</style>