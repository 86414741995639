<template>
  <div class="grid grid-cols-1 h-screen my-auto items-center text-center bg-cover">
    <div>
      <div class="text-secondary font-bold text-4xl">Diese Seite wurde leider nicht gefunden</div>
      <div class="text-primary uppercase font-bold text-5xl">Fehler 404</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorView"
}
</script>

<style scoped>

</style>