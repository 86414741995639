<template>
  <div class="grid grid-cols-1">
    <div class="text-secondary uppercase text-3xl font-bold font-heading text-headline">{{ title }}</div>
  </div>
</template>

<script>

export default {
  name: "HeaderComponent",
  props: {
    title: {
      required: true,
      type: String
    }
  }
}
</script>

<style scoped>

</style>