<template>
  <div class="flex flex-col flex-grow font-text text-text text-boc-gray" style="height: calc(100% - 65px)">
    <div class="flex flex-col fixed left-6 z-50">
      <img src="./assets/boc_logo_weiss.jpg" class="h-[107px]" alt="BOC-Logo"/>
    </div>

    <div class="flex flex-col flex-grow w-full">
      <router-view/>
    </div>

    <!-- install banner -->
    <div v-if="installBanner" class="bottom-0 sticky">
      <div class="flex flex-row gap-2 relative bottom-[62px] bg-primary text-white p-4 items-center">
        <i class="fa-regular fa-download"></i>
        <div class="font-bold">Als App installieren?</div>

        <div @click="install" class="underline">Ja</div>
        <div @click="neverShowAppInstallBanner" class="hover:underline">Nein</div>
      </div>
    </div>

    <div class="grid grid-cols-5 fixed justify-between text-center w-full bg-secondary inset-x-0 bottom-0 z-10">
      <div
          v-for="(navigationItem, index) of navigationBar" :key="index"
          class="text-center px-2 flex justify-center"
          @click="$router.push({path: navigationItem.path})"
      >
        <div v-if="navigationItem.icon"
             :class="isActive(navigationItem.name)  ? `bg-primary` : `hover:bg-primary`"
             class="icon-container flex justify-center items-center"
        >
          <i :class="navigationItem.size + ' ' + (isActive(navigationItem.name)  ? navigationItem.icon.filled : navigationItem.icon.regular)"
             class="text-white cursor-pointer"
          ></i>
        </div>

        <div v-else-if="navigationItem.img"
             class="grid grid-cols-1 place-items-center justify-items-center cursor-pointer icon-container"
             :class="`${isActive(navigationItem.name)  ? `bg-primary` : `hover:bg-primary`}`"
        >
          <img
              style="width: 36px;"
              :src="`${isActive(navigationItem.name) ? navigationItem.img.filled : navigationItem.img.regular}`"
              alt="icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      navigationBar: [
        {
          name: 'home',
          path: '/',
          icon: {
            regular: 'fa-thin fa-house-blank',
            filled: 'fa-solid fa-house-blank'
          },
          size: 'fa-small'
        },
        {
          name: 'card',
          path: '/card',
          match: [''],
          img: {
            regular: require('@/assets/icons/boc.svg'),
            filled: require('@/assets/icons/boc_filled.svg')
          },
          size: ''
        },
        {
          name: 'offers',
          path: '/offers',
          img: {
            regular: require('@/assets/icons/offer.svg'),
            filled: require('@/assets/icons/offer_filled.svg')
          },
          size: ''
        },
        {
          name: 'bookmarks',
          path: '/bookmarks',
          icon: {
            regular: 'fa-thin fa-heart',
            filled: 'fa-solid fa-heart'
          },
          size: 'fa-xs'
        },
        {
          name: 'map',
          path: '/map',
          icon: {
            regular: 'fa-thin fa-location-dot',
            filled: 'fa-solid fa-location-dot'
          },
          size: 'fa-xs'
        }
      ],
      installBanner: false,
      deferredPrompt: undefined
    }
  },
  mounted() {
    const show = localStorage.getItem('boc_show_install');
    if ((show !== undefined && show) ?? false)
      this.launchInstallAppListener()
  },
  methods: {
    isActive(name) {
      return this.$route.name === name || this.$route.matched.findIndex((match) => match.name === name) !== -1
    },
    install() {
      this.installBanner = false;

      if (!this.deferredPrompt)
        return;

      // Show the install prompt
      this.deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.neverShowAppInstallBanner();
        }
      });
    },
    postponeInstallApp() {
      this.installBanner = false;
    },
    neverShowAppInstallBanner() {
      this.installBanner = false;
      localStorage.setItem('boc_show_install', false);
    },
    launchInstallAppListener() {
      window.addEventListener('beforeinstallprompt', (e) => {
        // Prevent the mini-infobar from appearing on mobile
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        // Update UI notify the user they can install the PWA
        setTimeout(() => this.installBanner = true, 3000)
      });
    }
  }
}
</script>

<style lang="scss">
.icon {
  display: inline-block;
  overflow: hidden;
}

.icon-container {
  width: 62px;
  height: 62px;
}

html, body {
  height: 100%;
}

#venus-boc-pwa {
  height: 100%;
}
</style>
