<template>
  <div class="flex gap-4 container"
       :class="direction === 'horizontal' ? 'flex-col md:flex-row lg:flex-row flex-grow' : 'flex-col'">
    <!-- IMAGE CONTAINER -->
    <div class="m-auto cursor-pointer" style="width: 270px; height: 180px"
         @click="$router.push(`/detail/${entity._id}`)"
    >
      <img class="object-cover" style="width: 270px; height: 180px" :src="entity.getImageUrl()" alt="Bild"/>
    </div>

    <div class="flex flex-col flex-grow break-words pt-4">
      <!-- TYPES
      <div class="flex flex-row flex-wrap text-boc-gray">
        <div v-for="(type, index) of entity.types_all" :key="index" class="text-sm">
          <span v-if="index !== 0" class="px-2">|</span>
          <i :class="moduleStore.getValue(`datamodel.datamodel.entitytype.${type}.icon`)"></i>
          <span class="pl-1">{{ moduleStore.getValue(`datamodel.datamodel.entitytype.${type}.title`) }}</span>
        </div>
      </div>
      -->

      <!-- CREATED ON / MODIFIED ON
      <div v-if="entity.types_all.includes('Article')" style="line-height: 1.5">
        <span v-if="entity.dataPublished">{{
            moduleStore.getValue(`messages.detail.published_on`)
          }} {{ formatDate(entity.dataPublished, 'DD.MM.YYYY') }}</span>
        <span v-else></span>
      </div>
      -->

      <!--
      <div v-if="entity.sortDistance" class="text-sm text-gray">
        <span>ca. <span class="font-bold text-secondary">{{ entity.sortDistance ?? 0 }}</span> km</span>
        {{ moduleStore.getValue(`messages.general.distance`) }}
      </div>
      -->

      <!-- TITLE -->
      <div class="text-headline font-bold text-primary tracking-wide cursor-pointer"
           @click="$router.push(`/detail/${entity._id}`)"
      >
        {{ entity.getTranslation('name') }}
      </div>

      <!-- ADDRESS -->
      <div v-if="entity.address && entity.formatAddress(entity.address)" class="text-sm">
        <i class="fas fa-map-marker-alt mr-1"></i>
        <span>{{ entity.formatAddress(entity.address) }}</span>
      </div>

      <!-- SHORT DESCRIPTION
      <div class="text-sm text-gray whitespace-pre-wrap">
        <div v-html="entity.truncate(entity.getTranslation('shortDescription'), 200)"></div>
      </div>
      -->

      <!-- BUTTONS -->
      <div class="flex flex-row gap-2 flex-grow flex-wrap justify-end">
        <!-- TELEFON 
        <div v-if="entity.getContact('primary')[0]">
          <a v-if="entity.getTranslation('telephone', entity.getContact('primary')[0])"
             :href="`tel:${entity.getTranslation('telephone', entity.getContact('primary')[0])}`"
             :title="moduleStore.getValue(`messages.general.call`)"
             class="we2p-listing-result__button we2p-listing-result__button-phone d-print-none">
            <i class="fas fa-phone"></i>
          </a>
          <a v-else-if="entity.getTranslation('telephoneAlternative', entity.getContact('primary')[0])"
             :href="`tel:${entity.getTranslation('telephoneAlternative', entity.getContact('primary')[0])}`"
             :title="moduleStore.getValue(`messages.general.call`)"
             class="we2p-listing-result__button we2p-listing-result__button-phone d-print-none">
            <i class="fas fa-phone"></i>
          </a>
          <a v-else-if="entity.getTranslation('telephoneMobile', entity.getContact('primary')[0])"
             :href="`tel:${entity.getTranslation('telephoneMobile', entity.getContact('primary')[0])}`"
             :title="moduleStore.getValue(`messages.general.call`)"
             class="we2p-listing-result__button we2p-listing-result__button-phone d-print-none">
            <i class="fas fa-phone"></i>
          </a>
        </div>
        -->

        <div>
          <a class="cursor-pointer"
             :title="moduleStore.getValue(`messages.bookmark`)"
             data-marked="false"
             :data-id="entity._id"
             @click="entitiesStore.switchLike(entity._id)"
          >
            <i v-if="entitiesStore.isLiked(entity._id)" class="fa-solid fa-heart"></i>
            <i v-else class="fa-light fa-heart transition duration-300 ease-in-out hover:scale-125"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapStores} from "pinia";
import {useModuleStore} from "@/stores/module";
import {useEntitiesStore} from "@/stores/entities";

export default {
  name: "EntityComponent",
  props: {
    direction: {
      required: true,
      type: String
    },
    entity: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      processed: {}
    }
  },
  computed: {
    ...mapStores(useModuleStore, useEntitiesStore)
  }
}
</script>

<style scoped>
.container {
  #width: 270px !important;
  height: 100%;
}
</style>