<template>
  <div class="grid grid-cols-1 gap-2 boc-container pt-28">
    <div class="grid grid-cols-1">
      <header-component title="Karte aktivieren"/>
    </div>

    <div>
      <form class="flex flex-col gap-4">
        <div>
          <label class="block text-gray-700 text-text font-bold mb-2" for="lastname">
            Nachname eingeben
          </label>
          <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="lastname" type="text" placeholder="Nachname"
              @keydown.enter="activate"
              v-model="lastname"
          >
        </div>
        <div>
          <label class="block text-gray-700 text-text font-bold mb-2" for="code">
            Code eingeben
          </label>
          <input
              class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="code" type="text" placeholder="Code"
              @keydown.enter="activate"
              v-model="bookingCode"
          >
        </div>
        <div class="flex items-center justify-between">
          <button
              class="bg-secondary hover:bg-white hover:border hover:border-secondary hover:text-secondary text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              @click="activate"
          >
            Karte aktivieren
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/misc/HeaderComponent";
import {mapStores} from "pinia";
import {useBocCardStore} from "@/stores/bocCard";

export default {
  name: "LoginView",
  components: {HeaderComponent},
  data() {
    return {
      lastname: undefined,
      bookingCode: undefined
    }
  },
  computed: {
    ...mapStores(useBocCardStore)
  },
  methods: {
    activate() {
      if (!this.lastname || !this.bookingCode)
        return;

      this.bocCardStore.getBySelfCheckIn(this.lastname, this.bookingCode).then(() => {
        this.$router.push('/card');
      }).catch(() => {
        this.lastname = "";
        this.bookingCode = "";
      });
    }
  }
}
</script>

<style scoped>

</style>